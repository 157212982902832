<template>
  <div class="ds-wrapper">
    <footer class="footer-customer bg-container-60 py-6 px-4 sm:px-8 md:px-16 h-24 sm:h-30">
      <div class="flex justify-end">
        <div class="flex flex-col">
          <p class="text-xxs text-body">powered by</p>
          <img :src="logoUrl" :alt="appName" class="h-10 sm:h-14" />
        </div>
        <div class="divider mx-4 sm:mx-8"></div>
        <div class="flex flex-col justify-center">
          <p class="text-primary mb-2">Need help?</p>
          <p class="text-sm faq">Try our
            <span class="text-interaction-primary">
              <a :href="knowledgeBaseURL" target="_blank" class="no-underline hover:underline">help center</a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
const flipLogo = require("@/assets/images/logo/flippay-logo.svg");
const relloLogo = require("@/assets/images/logo/rellopay-logo.svg");

export default {
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      logoUrl: "",
      knowledgeBaseURL: "",
    };
  },

  created() {
    this.logoUrl = this.appName.toLowerCase() == "flippay" ? flipLogo : relloLogo;
    this.knowledgeBaseURL = this.appName.toLowerCase() == "flippay" ? "https://support.flippay.com.au/knowledge" : "https://help.rellopay.co";
  }
};
</script>

